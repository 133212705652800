import React from "react";
import zikoraImg from "../images/ZikoraLogoGreen 1.png";
import Divider from "./divider";
import { IoIosArrowDown } from "react-icons/io";
import { Box, Menu, MenuItem, Button, Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import MobileNav from "./mobileNav";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAccount, setOpenAccount] = React.useState(false);
  const [openChannels, setOpenChannels] = React.useState(false);
  const [onlineBanking, setOnlineBanking] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("personal");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenAccount(true);
  };
  const handleClickChannel = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenChannels(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenAccount(false);
  };
  const handleCloseChannel = () => {
    setAnchorEl(null);
    setOpenChannels(false);
  };

  const handleOpenOnlineBanking = (event) => {
    setAnchorEl(event.currentTarget);
    setOnlineBanking(true);
  };

  const handleCloseOnlineBanking = () => {
    setAnchorEl(null);
    setOnlineBanking(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "16px",
          fontFamily: "Circular Std",
          marginBottom: "2rem",
          "@media (max-width: 639px)": {
            padding: "1rem 1.2rem 0 1.2rem",
            width: "100vw",
            alignItems: "flex-end",
            marginBottom: "1.2rem",
          },
        }}
      >
        <RouterLink to="/">
          <img className="sm:w-10" src={zikoraImg} alt="" />
        </RouterLink>

        <Box
          sx={{
            "@media (max-width: 639px)": {
              display: "none",
            },
          }}
          className="flex space-x-20 ml-20 "
        >
          <div className="flex space-x-2 items-center">
            <RouterLink onClick={handleOpenOnlineBanking}>
              Online Banking
            </RouterLink>
            <Icon />
          </div>
          <div className="flex space-x-2 items-center">
            <RouterLink onClick={handleClickChannel}>Channels</RouterLink>
            <Icon />
          </div>
          <RouterLink to="/loans/">Loans</RouterLink>
          <div className="flex space-x-2 items-center">
            <RouterLink onClick={handleClick}>Accounts</RouterLink>
            <Icon />
          </div>
          <RouterLink to="/about/">About</RouterLink>
          <RouterLink to="/contact">Contact Us</RouterLink>

          {onlineBanking && (
            <Menu
              id="online-banking-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseOnlineBanking}
              MenuListProps={{
                "aria-labelledby": "online-banking-button",
              }}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "4px",
                  minWidth: "300px",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                  bgcolor: "#66A681",
                  padding: "4px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  mb: 0,
                  mx: 0.5,
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <Box
                  onClick={() => handleTabChange("personal")}
                  sx={{
                    flex: 1,
                    py: 0.75,
                    textAlign: "center",
                    cursor: "pointer",
                    bgcolor: activeTab === "personal" ? "white" : "transparent",
                    color: activeTab === "personal" ? "#66A681" : "white",
                    borderRadius:
                      activeTab === "personal" ? "3px 3px 0 0" : "3px",
                    fontSize: "13px",
                    fontWeight: 500,
                    position: "relative",
                    zIndex: 2,
                    transition: "all 0.2s ease",
                    "&:hover": {
                      bgcolor:
                        activeTab === "personal"
                          ? "white"
                          : "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  Personal
                </Box>
                <Box
                  onClick={() => handleTabChange("corporate")}
                  sx={{
                    flex: 1,
                    py: 0.75,
                    textAlign: "center",
                    cursor: "pointer",
                    bgcolor:
                      activeTab === "corporate" ? "white" : "transparent",
                    color: activeTab === "corporate" ? "#66A681" : "white",
                    borderRadius:
                      activeTab === "corporate" ? "3px 3px 0 0" : "3px",
                    fontSize: "13px",
                    fontWeight: 500,
                    position: "relative",
                    zIndex: 2,
                    transition: "all 0.2s ease",
                    "&:hover": {
                      bgcolor:
                        activeTab === "corporate"
                          ? "white"
                          : "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  Corporate
                </Box>
              </Box>

              {activeTab === "personal" ? (
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "3px",
                    p: 2,
                    mt: -0.5,
                    mx: 0.5,
                    position: "relative",
                    zIndex: 1,
                    minHeight: "140px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    component="a"
                    href="https://zikoramfb.qoreonline.com/login"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      width: "100%",
                      bgcolor: "#66A681",
                      color: "white",
                      textTransform: "none",
                      mb: 2.5,
                      mt: 2,
                      py: 1,
                      borderRadius: "3px",
                      fontSize: "13px",
                      fontWeight: 500,
                      transition: "background-color 0.2s ease",
                      "&:hover": {
                        bgcolor: "#558c6b",
                      },
                    }}
                  >
                    Login
                  </Button>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: 0.5,
                      mt: "auto",
                      pb: 0.5,
                    }}
                  >
                    <MuiLink
                      href="https://zikoramfb.qoreonline.com/onboarding/type"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: "#666",
                        textDecoration: "none",
                        fontSize: "12px",
                        transition: "color 0.2s ease",
                        "&:hover": {
                          color: "#333",
                          textDecoration: "underline",
                        },
                      }}
                    >
                      Register
                    </MuiLink>
                    <Box
                      sx={{
                        width: "1px",
                        height: "12px",
                        bgcolor: "#E0E0E0",
                        mx: 1,
                      }}
                    />
                    <RouterLink
                      to="/account/"
                      style={{
                        color: "#666",
                        textDecoration: "none",
                        fontSize: "12px",
                        transition: "color 0.2s ease",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.color = "#333";
                        e.target.style.textDecoration = "underline";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "#666";
                        e.target.style.textDecoration = "none";
                      }}
                    >
                      Open New Account
                    </RouterLink>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: "3px",
                    p: 2,
                    mt: -0.5,
                    mx: 0.5,
                    position: "relative",
                    zIndex: 1,
                    minHeight: "140px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    color: "#666",
                    fontSize: "13px",
                  }}
                >
                  Coming Soon
                </Box>
              )}
            </Menu>
          )}

          {openAccount && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <RouterLink to="/account/">Account</RouterLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <RouterLink to="/current-account/">Current Account</RouterLink>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <RouterLink to="/saving-account/">Saving Account</RouterLink>
              </MenuItem>
            </Menu>
          )}

          {openChannels && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseChannel}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleCloseChannel}>
                <RouterLink to="/main-channels">Banking Channel</RouterLink>
              </MenuItem>
              <MenuItem onClick={handleCloseChannel}>
                <RouterLink to="/mobile-banking">Mobile Banking</RouterLink>
              </MenuItem>
              <MenuItem onClick={handleCloseChannel}>
                <RouterLink to="/agency-banking">Agency Banking</RouterLink>
              </MenuItem>
              <MenuItem onClick={handleCloseChannel}>
                <RouterLink to="/ussd-banking">USSD Banking</RouterLink>
              </MenuItem>
            </Menu>
          )}
        </Box>
        {/* <Button title='Join Now' style={{
            padding: '10px 18px',
            borderRadius: '0.5rem',
            fontWeight: 'bold',
            backgroundColor: 'white',
            color: 'black',
            textTransform: 'none',
            '@media (max-width: 639px)': {
              display: 'none',
          },

        }}/> */}

        <MobileNav />
      </Box>
      <Divider />
    </>
  );
};

export default Header;

const Icon = () => {
  return (
    <div
      style={{
        fontSize: "10px",
      }}
    >
      <IoIosArrowDown />
    </div>
  );
};
